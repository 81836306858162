<template>
	<div class="set-stats">
		<!-- Sign Up Form -->
		<h1>Добавить статистику</h1>
		<a-card :bordered="false" class="card-signup header-solid">

			<a-form-model
				id="components-form-demo-normal-login"
				:form="form"
				class="login-form"
				@submit="handleSubmit"
			>
				<a-form-model-item style="display: flex; justify-content: center; text-align: center">
					<a-date-picker v-model="date" placeholder="Дата игры" :format="'DD.MM.YYYY'" :allowClear="false"/>
				</a-form-model-item>

				<a-form-model-item :class="'set-stats__row'">
					<a-row type="flex" :gutter="10">
						<a-col :xs="{ span: 10 }" :lg="{ span: 12 }">
							<div style="margin-bottom: 5px">Игрок</div>
							<a-auto-complete
								v-model="player.name"
								:data-source="dataSource"
								placeholder="Имя игрока"
								:filter-option="filterOption"
								:allowClear="true"
								class="ant-dropdown-placement-topLeft"
								:defaultOpen="false"
								:open="player.name !== undefined && player.name.length > 2 && focused"
								@change="nameChange"
								@focus="focused = true"
								@blur="focused = false"
							/>
						</a-col>
						<a-col :xs="{ span: 7 }" :lg="{ span: 6 }">
							<div style="margin-bottom: 5px">Голы</div>
							<a-button-group>
								<a-button class="btn-minus" @click="player.goals > 0 ? player.goals-=1 : null">-</a-button>
								<a-input class="counter" v-model="player.goals"/>
								<a-button class="btn-plus" @click="player.goals+=1">+</a-button>
							</a-button-group>
						</a-col>
						<a-col :xs="{ span: 7 }" :lg="{ span: 6 }">
							<div style="text-align: right; margin-bottom: 5px">Передачи</div>
							<a-button-group>
								<a-button class="btn-minus" @click="player.pass > 0 ? item.pass-=1 : null">-</a-button>
								<a-input class="counter" v-model="player.pass"/>
								<a-button class="btn-plus" @click="player.pass+=1">+</a-button>
							</a-button-group>
						</a-col>
					</a-row>
				</a-form-model-item>

				<a-form-item>
					<a-button type="primary" block html-type="submit" class="login-form-button">
						Сохранить
					</a-button>
				</a-form-item>
			</a-form-model>
		</a-card>
		<!-- / Sign Up Form -->
	</div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/ru';

export default ({
	data() {
		return {
			name: 'AddStat',
			date: moment().subtract(1, 'd'),
			//date: moment(),
			result: [],
			player: {
				name: '',
				goals: 0,
				pass: 0,
			},

			dataSource: [],
			focused: false,
		}
	},
	mounted() {
		this.getPlayers();
		this.loadFromStorage();
	},
	beforeCreate() {
		// Creates the form and adds to it component's "form" property.
		this.form = this.$form.createForm(this, { name: 'normal_login' });
	},
	watch: {
		/*
		date(newDate) {
			console.log('newDate', newDate);
			this.dateDB =  moment(newDate).format('DD-MM-YYYY');
		}*/
	},
	computed: {
		dateDB() {
			return moment(this.date).format('YYYY-MM-DD');
		},

		validName() {
			return this.dataSource.some((child) => child.value === this.player.name)
		}
	},
	methods: {
		getPlayers() {
			const data = {
				method: 'add-stat-page',
				date: this.dateDB,
			}

			axios
				.post('https://hcultras.kz/api/', JSON.stringify(data))
				.then(response => {
					if (response.status === 200) {
						const players = response.data.stats;
						players.forEach((stats, index) => {
							this.dataSource.push({
								value: stats.name,
								text: stats.player,
								stats_id: stats.stats_id,
							});

							if (this.player.name === stats.name) {
								this.player.goals = +stats.goals;
								this.player.pass = +stats.pass;
								this.player.stats_id = +stats.stats_id;
							}
						});
					}
				})
				.catch(error => console.log(error));
		},

		loadFromStorage() {
			if (localStorage) {
				const localData = JSON.parse(localStorage.getItem('player'));
				if (localData.name) {
					this.player.name = localData.name;
				}
			}
		},

		nameChange() {
			if (this.player.name !== '' && this.validName) {
				const localData = {
					name: this.player.name,
				}
				localStorage.setItem('player', JSON.stringify(localData));
			}
		},

		filterOption(input, option) {
			return (
				input.length > 2 ? option.componentOptions.children[0].text.toUpperCase().includes(input.toUpperCase()) : null
			);
		},

		savePlayers() {

			if (!this.player.name || this.player.name.length < 1) {
				this.$message.error('Выберите имя игрока из списка', 5);
				return;
			}

			if (!this.player.goals && !this.player.pass) {
				this.$message.error('Не указан гол или пас', 5);
				return;
			}

			if (!this.validName) {
				this.$message.error('Выберите имя игрока из списка', 5);
				return;
			}

			const data = {
				method: 'add-stat-page',
				date: this.dateDB,
				stats: this.player,
			}

			axios
				.post('https://hcultras.kz/api/', JSON.stringify(data))
				.then(response => {
					if (response.status === 200) {
						console.log(response.data);
						this.$message.success('Статистика успешно отправлена');
					}
				})
				.catch(error => console.log(error));
		},

		// Handles input validation after submission.
		handleSubmit(e) {
			e.preventDefault();
			this.form.validateFields((err, values) => {
				if (!err) {
					// console.log('Received values of form: ', values) ;
					this.savePlayers();
				}
			});
		},
	},
})

</script>

<style lang="scss">
h1 {
	font-size: 28px;
	text-align: center;
}

.set-stats {
	display: flex;
	flex-direction: column;
}

input.ant-input {
	padding: 4px 6px;
	min-width: 30px;
}

.ant-btn {
	&.btn-minus,
	&.btn-plus {
		padding: 0;
		min-width: 30px;
	}
}

.counter {
	border-radius: 0;
	border-left: none;
	border-right: none;
	text-align: center;
}

.ant-form-item {
	&.set-stats__row {
		margin-bottom: 15px;
	}
}

.ant-form-item-control {
	line-height: normal;
}
</style>